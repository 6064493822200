<template>
  <div class="maintenance">
    <v-container class="mt-2">
      <v-row>
        <v-col class="pb-0" cols="12">
          <v-autocomplete
            autocomplete="off"
            :items="customers"
            class="mb-0"
            color="primary"
            dense
            label="Wartung von Kunden suchen"
            auto-select-first
            v-model="currentCustomer"
            @change="findCustomer()"
            :item-text="(item) => item.name + ', ' + item.street"
            item-value="id"
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="maintenance in maintenances"
          v-bind:key="maintenance.id"
          lg="3"
          md="4"
          sm="6"
          cols="12"
        >
          <v-card elevation="2" outlined class="maintenanceComp pb-0">
            <maintenanceCard
              v-if="maintenance.id !== expanded"
              :maintenance="maintenance"
              :customers="customers"
            />

            <div v-if="maintenance.id === expanded">
              <v-app-bar flat>
                <v-toolbar-title class="text-h6 pl-0">
                  {{ customerName(maintenance.customer_id) }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  @click="deleteMaintenance(maintenance)"
                  icon
                  class="mr-1"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                <v-btn @click="editMaintenance(maintenance)" icon>
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-app-bar>
              <v-card-subtitle class="pb-4">
                <v-row>
                  <v-col style="flex-grow: 0"> Techniker: </v-col>
                  <v-col v-if="techName">
                    <strong>{{ techName }} </strong>
                  </v-col>
                  <v-col v-if="!techName">
                    <v-skeleton-loader
                      v-if="!techName"
                      max-width="50"
                      type="text"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <fileComponent :parent="maintenance" type="maintenanceMedia" />
              </v-card-text>

              <v-card-text>
                <v-form
                  ref="maintenanceForm"
                  id="maintenanceForm"
                  v-model="maintenanceValid"
                  autocomplete="off"
                >
                  <maintenanceFields :maintenance="maintenance" />
                </v-form>
              </v-card-text>
            </div>

            <v-card-actions style="width: 100%">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                v-if="maintenance.id === expanded"
                block
                @click="closeItem()"
              >
                <v-icon>mdi-chevron-up</v-icon>
                Weniger
              </v-btn>
              <v-btn
                color="primary"
                text
                v-if="maintenance.id !== expanded"
                block
                @click="expandItem(maintenance)"
              >
                <v-icon>mdi-chevron-down</v-icon>
                Mehr
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <div class="text-center">
        <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn color="accent" text v-bind="attrs" @click="snackbar = false">
              Schließen
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import fileComponent from "@/components/Files.vue";
import maintenanceCard from "@/components/MaintenanceCard.vue";
import maintenanceFields from "@/components/MaintenanceFields.vue";

export default {
  data: () => ({
    customers: [],
    maintenances: [],

    currentCustomer: null,
    datpickerOpen: false,

    techName: null,
    maintenanceValid: false,
    expanded: null,

    snackbar: false,
    snackbarText: "",
    snackbarTimeout: 3000,

    newMaintenanceValid: false,
    dateRule: [(v) => !!v || "Bitte Datum angeben"],
    customerRule: [(v) => !!v || "Bitte Kunde angeben"],
    noteRule: [
      (v) => !!v || "Bitte Wartung beschreiben",
      (v) => (v && v.length > 10) || "Bitte Wartung besser beschreiben",
    ],
    newMaintenance: {
      customer_id: null,
      created: new Date().toISOString().split("T")[0],
      note: "",
      media: [],
    },
  }),
  components: {
    fileComponent,
    maintenanceCard,
    maintenanceFields,
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadCustomers();
    },
    findCustomer(){
      const t = this;
      axios
        .get(
          process.env.VUE_APP_API_PATH +
            "/data/maintenances?mode=allForCustomer&cfid=" + this.currentCustomer
        )
        .then(function (response) {
          if (response.status == 200) {
            if(process.env.VUE_APP_DEBUG === "true")
              console.log("all customers maintenance loaded", response);
            if(response.data.length){
              t.maintenances = response.data;
            }else{
              t.snackbarText = "Keine Wartung vorhanden.";
              t.snackbar = true;
            }
          }
        });
    },
    cropText(text, len){
      return text.length > len ? 
                    text.substring(0, len - 3) + "..." : 
                    text;
    },
    isExpanded(item) {
      return this.expanded === item.id;
    },
    expandItem(item) {
      this.expanded = item.id;
      this.loadTechName(item);
      // this.$parent.$parent.$parent.asdasd = item.id;
      // console.log(this.$parent.$parent.$parent.asdasd)
      item.created = this.formatDate(item.created);
    },
    closeItem(item) {
      (this.techName = null), (this.expanded = null);
    },
    customerName(customer_id) {
      return this.customers.find((c) => c.id == customer_id).name;
    },
    formatDate(created) {
      return created.split(" ")[0];
    },
    editMaintenance(item) {
      const t = this;
      const formData = new FormData(document.getElementById("maintenanceForm"));
      axios
        .post(
          process.env.VUE_APP_API_PATH + "/data/updateMaintenance/" + item.id,
          formData
        )
        .then(function (response) {
          if (response.status == 200) {
            if(process.env.VUE_APP_DEBUG === "true")
              console.log("customer updated", response);
            localStorage.maintenances = JSON.stringify(t.maintenances);
            t.snackbarText = "Wartung erfolgreich gespeichert";
          } else {
            t.snackbarText = "Wartung konnten nicht gespeichert werden!";
          }
          t.snackbar = true;
        })
        .catch(function () {
          t.snackbarText = "Server nicht erreichbar. Sind sie offline?";
          t.snackbar = true;
        });
    },
    deleteMaintenance(item) {
      if (confirm("Wartung wirklich löschen?")) {
        const t = this;
        axios
          .delete(process.env.VUE_APP_API_PATH + "/data/maintenance/" + item.id)
          .then(function (response) {
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true")
                console.log("maintenance deleted", response);
              t.maintenances = t.maintenances.filter(function (current) {
                return current.id != item.id;
              });
              localStorage.maintenances = JSON.stringify(t.maintenances);
              t.snackbarText = "Wartung wurde gelöscht";
              t.deleted = true;
            } else {
              t.snackbarText = "Wartung konnte nicht gelöscht werden";
            }
            t.snackbar = true;
          })
          .catch(function () {
            t.snackbarText = "Server nicht erreichbar. Sind sie offline?";
            t.snackbar = true;
          });
      }
    },
    loadTechName(item) {
      const t = this;
      axios
        .get(
          process.env.VUE_APP_API_PATH + "/data/username?uid=" + item.created_by
        )
        .then(function (response) {
          if (response.status == 200) {
            if(process.env.VUE_APP_DEBUG === "true")
              console.log("tech name loaded", response);
            t.techName = response.data.name;
          }
        });
    },
    addMaintenance() {
      this.$refs.newMaintenanceForm.validate();
      const t = this;
      if(process.env.VUE_APP_DEBUG === "true")
        console.log(this.newMaintenance, this.newMaintenanceValid);
      if (this.newMaintenanceValid) {
        const formData = new FormData(
          document.getElementById("newMaintenanceForm")
        );
        axios
          .post(process.env.VUE_APP_API_PATH + "/data/maintenance", formData)
          .then(function (response) {
            if(process.env.VUE_APP_DEBUG === "true") console.log(response);
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true")
                console.log("maintenance added", response);
              t.$refs.newMaintenanceForm.reset();
              t.maintenances.unshift(response.data);
              localStorage.maintenances = JSON.stringify(t.maintenances);
              t.snackbarText = "Wartung erfolgreich angelegt";
              t.snackbar = true;
              setTimeout(function(){ //weird bugfix
                t.newMaintenance.created = new Date().toISOString().split("T")[0];
              }, 1);
            } else {
              t.snackbarText = "Wartung konnten nicht angelegt werden!";
            }
            t.snackbar = true;
          })
          .catch(function () {
            t.snackbarText = "Server nicht erreichbar. Sind sie offline?";
            t.snackbar = true;
          });
      }
    },
    //todo merge with maintenance promise
    loadCustomers() {
      if (localStorage.customers) {
        this.customers = JSON.parse(localStorage.customers);
      }
      const t = this;
      axios
        .get(process.env.VUE_APP_API_PATH + "/data/customers")
        .then(function (response) {
          if (response.status == 200) {
            if(process.env.VUE_APP_DEBUG === "true")
              console.log("customers loaded", response);
            t.customers = response.data;
            localStorage.customers = JSON.stringify(response.data);
          }
        });
    },
    loadMaintenance(showMsg) {
      if (
        localStorage.maintenances &&
        localStorage.maintenances != "undefined"
      ) {
        this.maintenances = JSON.parse(localStorage.maintenances);
      }
      const t = this;
      axios
        .get(process.env.VUE_APP_API_PATH + "/data/maintenances")
        .then(function (response) {
          if (response.status == 200) {
            if(process.env.VUE_APP_DEBUG === "true")
              console.log("maintenances loaded", response);
            t.maintenances = response.data;
            localStorage.maintenances = JSON.stringify(response.data);
            if (showMsg) {
              t.snackbarText = "Wartungen erfolgreich geladen";
              t.snackbar = true;
            }
          } else {
            t.snackbarText = "Wartungen konnten nicht geladen werden";
            t.snackbar = true;
          }
        })
        .catch(function () {
          t.snackbarText = "Server nicht erreichbar. Sind sie offline?";
          t.snackbar = true;
        });
    },
  },
};
</script>

<style>
</style>